import React, { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ServiceButton from '../../components/ServiceButton';

//images
import Service from '../../assets/images/aboutservice/service.webp';
import Import from '../../assets/images/aboutservice/import.webp';
import Garments from '../../assets/images/aboutservice/garment.webp';
import Handicraft from '../../assets/images/aboutservice/handicraft.webp';
import Health from '../../assets/images/aboutservice/heallth.webp';
import Herbal from '../../assets/images/aboutservice/herbal.webp';
import Honey from '../../assets/images/aboutservice/honey.webp';
import Tea from '../../assets/images/aboutservice/tea.webp';
import HempBag from '../../assets/images/aboutservice/hempbag.webp';
import ServiceCard from '../../components/ServiceCard';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const services = [
    {
      name: 'Housekeeping and Cleaning Service',
      description:
        'Our professional cleaning team provides comprehensive cleaning services for residential and commercial properties. We take pride in using eco-friendly cleaning products to provide a clean and healthy living environment. Our team is equipped with the latest tools and equipment to provide deep cleaning solutions for every nook and corner of your property.',
      image: Service,
      ref: useRef(null),
    },
    {
      name: 'Worldwide Import and Export',
      description:
        'At Sunrise MultitradeLink, we offer a wide range of import and export services. Our team of experts handles all aspects of the process, from product sourcing to logistics and transportation. We work with a network of suppliers and buyers to ensure that our clients receive the best quality products at competitive prices.',
      image: Import,
      ref: useRef(null),
    },
    {
      name: 'Garment Online Business',
      description:
        'Our garment online business offers a wide variety of trendy clothing options for men and women. We are committed to providing high-quality clothing that is comfortable and stylish. Our products are made from the best quality fabrics, ensuring durability and comfort.',
      image: Garments,
      ref: useRef(null),
    },
    {
      name: 'Handicrafts Products',
      description:
        'We offer a range of unique and beautiful handicrafts products that are carefully crafted by skilled artisans. Our products are perfect for adding a touch of elegance to your home or gifting to your loved ones. From decorative items to furniture, we have a wide range of handicrafts products to choose from.',
      image: Handicraft,
      ref: useRef(null),
    },
    {
      name: 'Health and Beauty Products',
      description:
        'We believe in the importance of taking care of our bodies with natural and organic products. Our health and beauty products are carefully selected to provide you with the best quality products available. From skincare to hair care, we have a wide range of natural and organic products to choose from.',
      image: Health,
      ref: useRef(null),
    },
    {
      name: 'Herbal Products',
      description:
        'Herbal products have been used for centuries for their medicinal properties. Our range of herbal products includes supplements, teas and essential oils. Our products are carefully sourced from trusted suppliers to ensure their effectiveness.',
      image: Herbal,
      ref: useRef(null),
    },
    {
      name: 'Nature Pure Organic Honey',
      description:
        'Our Nature Pure Organic Honey is sourced from the finest beekeepers to ensure the highest quality honey. Our honey is raw and unfiltered, retaining all the natural health benefits. Our honey is perfect for adding sweetness to your food or as a natural remedy for various ailments.',
      image: Honey,
      ref: useRef(null),
    },
    {
      name: 'Natural Pure Organic Coffee and Tea',
      description:
        'Our Natural Pure Organic Coffee and Tea are carefully selected to provide you with the best quality coffee and tea available. Our coffee is made from the finest beans, roasted to perfection. Our tea is made from organic tea leaves, providing a natural and healthy drink option.',
      image: Tea,
      ref: useRef(null),
    },
    {
      name: 'Hemp Himalayan Products (THC free)',
      description:
        'Our Hemp Himalayan Products are THC free and provide a natural remedy for various ailments. Our range of products includes supplements, oils, and creams. Our products are carefully sourced from trusted suppliers to ensure their effectiveness.',
      image: HempBag,
      ref: useRef(null),
    },
  ];

  return (
    <section className='about--us'>
      <Container>
        <Row>
          <Col md={6} className='fixed-column'>
            <section className='about--us__overview'>
              <div className='about--us__overview--header'>
                <h2>About us</h2>
                <p>
                  Welcome to Sunrise MultitradeLink, your one-stop-shop for a
                  wide range of products and services. Our mission is to provide
                  our clients with the highest quality goods and services to
                  meet their daily needs. Our services include:
                </p>
              </div>

              <ServiceCard services={services} />
            </section>
          </Col>
          <Col md={6} className=' scrolling-column'>
            <section className='about--us__detail'>
              <ul className='about--us__detail--list'>
                {services.map((service, i) => (
                  <li
                    ref={service.ref}
                    key={i}
                    id={service.name}
                    className='about--us__detail--list-item'
                  >
                    <h4>
                      {i + 1}. {service.name}:
                    </h4>
                    <p>{service.description}</p>
                    <figure>
                      <img src={service.image} />
                    </figure>
                  </li>
                ))}
              </ul>
            </section>
          </Col>
        </Row>
      </Container>
      <ServiceButton services={services} />
    </section>
  );
};

export default AboutUs;
