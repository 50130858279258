import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux/es/exports';

import BrandImage from '../../assets/images/Sunrise.png';
import Account from '../../assets/icons/profile.png';
import Cart from '../../assets/icons/shopping-cart.png';
import Chevrone from '../../assets/icons/chevron.svg';
import { getCategoriesAsync } from '../../redux/categorySlice';
import NavSkeletons from '../Skeletons/NavSkeletons';
import { getSubscriberDetailsAsync } from '../../redux/subscriberSlice';
import { getCurrencyValueAsync } from '../../redux/productSlice';
import { countryDetails } from '../../data';

const Navbar = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.loading);
  const { user, userDetails } = useSelector((state) => state.subscribers);
  const { cartItems } = useSelector((state) => state.cart);

  const [searchKey, setSearchKey] = useState('');
  useEffect(() => {
    if (user) {
      dispatch(getSubscriberDetailsAsync());
    }
  }, [user]);

  const [currencyCode, setCurrencyCode] = useState('NOK');
  const [isCalled, setIsCalled] = useState(false);

  const currencyValue = JSON.parse(localStorage.getItem('currencyValue'));
  useEffect(() => {
    const fetch = async () => {
      await dispatch(
        getCurrencyValueAsync(
          currencyValue && !isCalled
            ? {
                currencyCode: currencyValue.currencyCode,
                countryCode: currencyValue.ISOCode,
              }
            : { currencyCode }
        )
      ).unwrap();
    };
    fetch();
  }, [dispatch, currencyCode]);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (userDetails.currencyCode) {
        setCurrencyCode(userDetails.currencyCode);
        setIsCalled(true);
      }
    } else if (currencyValue) {
      setCurrencyCode(currencyValue.currencyCode);
    }
  }, [userDetails]);

  if (loading) return <NavSkeletons />;

  return (
    <>
      <Container>
        <section className='topnavInfo'>
          <div className='topnavInfo-left'>
            <i className='fa fa-phone'></i> +47 455 34 581 &nbsp;&nbsp; |
            &nbsp;&nbsp;&nbsp;
            <i className=' fa fa-envelope'></i> info@sunrisemultitradelink.com
          </div>
          <div className='topnavInfo-right'>
            {userDetails && (
              <div className='topnavInfo-right_user'>{`Welcome ${userDetails?.name}`}</div>
            )}
            <div>
              <Form.Group
                // style={{ width: "8rem", marginRight: "0.5rem" }}
                controlId='formBasicEmail'
                className='topnavInfo-right_country'
              >
                <Form.Select
                  name='country'
                  defaultValue='NOK'
                  onChange={(e) => {
                    setCurrencyCode(e.target.value);
                    setIsCalled(true);
                  }}
                  value={currencyCode}
                  className='topnavInfo-right_country-select'
                >
                  {countryDetails.map((country, i) => {
                    return (
                      <option key={i} value={country.CurrencyCode}>
                        {country.Country}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </section>
      </Container>
      <section className='navMainWrapper'>
        <Container>
          <nav className='navWrapper'>
            <figure className='navWrapper-brand'>
              <Link to='/'>
                <img src={BrandImage} alt='' />
              </Link>
            </figure>
            <section className='navWrapper-search'>
              <input
                type='search'
                name='search'
                value={searchKey}
                onKeyUp={(e) =>
                  e.key === 'Enter' && navigate('/search', { state: searchKey })
                }
                id=''
                onChange={(e) => setSearchKey(e.target.value)}
                placeholder='search here ...'
              />
              <button
                className='bton bton--sm bton--primary ms-1 mobSearchBton'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/search', { state: searchKey });
                }}
              >
                <i className='fa fa-search'></i>{' '}
                <span className='mobsearch'>search</span>
              </button>
            </section>
            {/* <section>
              <Form.Group
                style={{ width: "8rem", marginRight: "0.5rem" }}
                controlId="formBasicEmail"
              >
                <Form.Select
                  name="country"
                  defaultValue="Norway"
                  onChange={(e) => {
                    setCurrencyCode(e.target.value);
                    setIsCalled(true);
                  }}
                >
                  {countryDetails.map((country, i) => {
                    return (
                      <option key={i} value={country.CurrencyCode}>
                        {country.Country}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </section> */}
            <section className='navWrapper-carts'>
              {user ? (
                <span className='navWrapper-carts-login'>
                  <Link to='/account'>
                    <button className='bton bton--nacked'>
                      <img src={Account} alt='' /> Account
                    </button>
                  </Link>
                </span>
              ) : (
                <span className='navWrapper-carts-login'>
                  <Link to='/login'>
                    <button className='bton bton--nacked'>
                      <i className='fa fa fa-sign-in'></i> Login
                    </button>
                  </Link>
                </span>
              )}

              <span className='myCart'>
                <Link to='/cart'>
                  <img src={Cart} alt='' /> &nbsp; <span>Cart</span>
                  <div className='cartCount'>{cartItems?.length}</div>
                </Link>
              </span>
            </section>
          </nav>
        </Container>
        <section>
          <nav className='navbarBox'>
            <Container>
              <ul className='menu p-0 m-0'>
                {categories &&
                  categories.map((cat) => {
                    const data = {
                      cat_id: cat._id,
                      subData: null,
                      sub2Data: null,
                    };
                    return (
                      <>
                        <li className='menu--items'>
                          <Link
                            to={`/category/${cat.slug}`}
                            state={data}
                            className='navitems'
                          >
                            {cat.name}{' '}
                          </Link>
                          {cat.subCategory.length > 0 && (
                            <img src={Chevrone} alt='' />
                          )}
                          {cat.subCategory.length > 0 && (
                            <section className='sub--menu'>
                              {cat.subCategory &&
                                cat.subCategory.map((sub) => {
                                  const data = {
                                    cat_id: cat._id,
                                    subData: { _id: sub._id, name: sub.name },
                                    sub2Data: null,
                                  };
                                  return (
                                    <section className='subcatWrapper'>
                                      <div className='subcatWrapper--title'>
                                        <Link
                                          to={`/category/${cat.slug}`}
                                          state={data}
                                        >
                                          {sub.name}
                                        </Link>
                                      </div>
                                      <ul className='subcatWrapper--itemholder'>
                                        {sub.subCategory2 &&
                                          sub.subCategory2.map((sub2) => {
                                            const data = {
                                              cat_id: cat._id,
                                              subData: {
                                                _id: sub._id,
                                                name: sub.name,
                                              },
                                              sub2Data: {
                                                _id: sub2._id,
                                                name: sub2.name,
                                              },
                                            };
                                            return (
                                              <li className='subcatWrapper--itemholder__items'>
                                                <Link
                                                  to={`/category/${cat.slug}`}
                                                  state={data}
                                                >
                                                  {sub2.name}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </section>
                                  );
                                })}
                            </section>
                          )}
                        </li>
                      </>
                    );
                  })}
                <li className='menu--items'>
                  <Link className='navitems' to={`/cleaning`}>
                    Cleaning Facilities
                  </Link>
                </li>
              </ul>
            </Container>
          </nav>
        </section>
      </section>

      <section className='mobileWrapper'>
        <section className='mobileNavbar'>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-home'></i>
                <div className='mobileNavbar-items-name'>Home</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/mobcat'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-sliders'></i>
                <div className='mobileNavbar-items-name'>Categories</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/cart'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-shopping-cart
'
                ></i>
                <div className='mobileNavbar-items-name'>Cart</div>
                {cartItems.length > 0 && (
                  <div className='cartMobCount'>{cartItems.length}</div>
                )}
              </section>{' '}
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/more'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-gear
'
                ></i>
                <div className='mobileNavbar-items-name'>More</div>
              </section>{' '}
            </NavLink>
          </section>
        </section>
      </section>
    </>
  );
};

export default Navbar;
