import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Bill from "../../components/Bill/index";
import paymentAcceptance from "../../assets/images/netsLogoColored.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  addOrderAsync,
  changeShippingDetails,
  getBuyNowCartUpdateDetailsAsync,
  getCartUpdateDetailsAsync,
  removeCart,
  resetQuickBuy,
} from "../../redux/cartSlice";
import axios from "axios";
import Choosepayment from "../../components/Choosepayment";
// import Message from '../../components/Message/Message';

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const shippingPrice = location.state.shippingPrice;
  const storePickup = location.state.storePickup;
  const details = JSON.parse(localStorage.getItem("details"));

  const { user } = useSelector((state) => state.subscribers);
  const {
    shippingDetails: sDetails,
    billingDetails: bDetails,
    buyNowTotal,
    buyNowCartItems,
    buyNowGrandTotal,
    total,
    cartItems,
    ...cart
  } = useSelector((state) => state.cart);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/");
  };

  useEffect(() => {
    if (!storePickup) {
      !details &&
        location.pathname.includes("buy-now") &&
        navigate("/shipping/buy-now");
      !details &&
        !location.pathname.includes("buy-now") &&
        navigate("/shipping");
    }
  }, [details, storePickup]);

  useEffect(() => {
    if (!storePickup && (!sDetails || !bDetails)) {
      dispatch(
        changeShippingDetails({
          shippingDetails: details?.details?.shippingDetails,
          billingDetails: details?.details?.billingAddress,
        })
      );
    }
  }, [dispatch, sDetails, bDetails, storePickup]);

  useEffect(() => {
    if (cart?.isOrderPlaceSuccess) {
      setShow(true);
      dispatch(removeCart());
    }
  }, [cart?.isOrderPlaceSuccess]);

  useEffect(() => {
    if (cart?.quickBuySuccess) {
      setShow(true);
      dispatch(resetQuickBuy());
    }
  }, [cart?.quickBuySuccess]);

  useEffect(() => {
    if (success) {
      if (!user) {
        navigate("/login");
        return;
      } else {
        const emptyDetails = {
          name: "",
          phone: "",
          email: "",
          address: "",
          apartmentNumber: "",
          city: "",
          country: "",
          postalCode: "",
        };
        dispatch(
          addOrderAsync({
            order: {
              details: {
                billingDetails: storePickup ? emptyDetails : bDetails,
                shippingDetails: storePickup ? emptyDetails : sDetails,
              },
              cart: !location.pathname.includes("buy-now")
                ? { cartItems, quickBuy: false }
                : {
                    cartItems: buyNowCartItems,
                    quickBuy: true,
                  },
              shippingPrice: shippingPrice,
              storePickup: storePickup,
            },
          })
        );
      }
    }
  }, [success, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.post("/api/orders/create-payment", {
          storePickup: storePickup,
          order: {
            cart: !location.pathname.includes("buy-now")
              ? {
                  cartItems: cartItems,
                  quickBuy: false,
                  toCountryCode: details?.details.shippingDetails.country,
                  toPostalCode: details?.details.shippingDetails.postalCode,
                  currencyCode: localStorage.getItem("currencyValue")
                    ? JSON.parse(localStorage.getItem("currencyValue"))[
                        "currencyCode"
                      ]
                    : "NOK",
                }
              : {
                  cartItems: buyNowCartItems,
                  quickBuy: true,
                  toCountryCode: details?.details.shippingDetails.country,
                  toPostalCode: details?.details.shippingDetails.postalCode,
                  currencyCode: localStorage.getItem("currencyValue")
                    ? JSON.parse(localStorage.getItem("currencyValue"))[
                        "currencyCode"
                      ]
                    : "NOK",
                },
          },
        });

        const { paymentId } = response.data;

        if (paymentId) {
          const checkoutOptions = {
            checkoutKey: "live-checkout-key-ed258801d15d46f886d5fda67be44b5d", // Replace!
            paymentId: paymentId,
            containerId: "checkout-container-div",
          };
          // eslint-disable-next-line no-undef
          const checkout = new Dibs.Checkout(checkoutOptions);
          checkout.on("payment-completed", function (response) {
            setSuccess(true);
          });
        } else {
          console.log("Expected a paymentId"); // No paymentId provided,
          // window.location = 'https://sunrisetest.netlify.app/cart'; // go back to cart.html
          // window.location = 'http://sunrisetest.netlify.app/cart'; // go back to cart.html
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("buy-now") && cartItems.length) {
      dispatch(getCartUpdateDetailsAsync({ cart: { cartItems } }));
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("buy-now") && buyNowCartItems?.length) {
      dispatch(
        getBuyNowCartUpdateDetailsAsync({
          buyNowCart: { buyNowCartItems },
        })
      );
    }
  }, []);

  return (
    <>
      <section className="shippingWrapper">
        <Container>
          <Row>
            <Col md={7}>
              <section className="shippingForm ">
                <section className="mt-3">
                  <Row>
                    <section className="paymentMethod">
                      <img
                        src={paymentAcceptance}
                        alt=""
                        style={{ width: "10rem", marginTop: "1rem" }}
                      />
                      {<div id="checkout-container-div"></div>}
                    </section>
                  </Row>
                </section>
              </section>
            </Col>

            <Col xs={{ order: "first" }} md={{ order: "last" }}>
              <Bill page="checkout" shippingPrice={shippingPrice} />
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section className="text-center">
            <div>
              <i
                className="fa  fa-angellist mb-3 "
                style={{ color: "green", fontSize: "3.5rem" }}
              ></i>
            </div>
            <strong>Your Order Has been Placed Sucessfully!!!</strong>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bton bton--full bton--primary"
            onClick={handleClose}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Checkout;
