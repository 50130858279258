import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import paymentAcceptance from "../../assets/images/netsLogoColored.svg";
import VisaMaster from "../../assets/images/mastercard-visa.jpg";
import tiktokIcon from "../../assets/images/tiktok.svg";
import American from "../../assets/images/americanExp.png";
import Vipps from "../../assets/images/vipps.png";

const Footer = () => {
  return (
    <>
      <div className="movfooter"></div>
      <section role="Footer">
        <Container>
          <Row className="justify-content-between">
            <Col md={3}>
              <section className="foooterInfo">
                <header className="Links-title">
                  <h4>About Sunrise</h4>
                </header>
                <p>
                  We take great pride in curating a collection of authentic and
                  high-quality products that are sure to meet your needs and
                  exceed your expectations. From stunning ethnic wear to trendy
                  western apparel, our garments are made from the finest fabrics
                  and crafted with care to ensure that you look and feel your
                  best. Our handicrafts are equally exceptional, each piece
                  lovingly handmade by skilled artisans using traditional
                  techniques that have been passed down through generations.
                </p>
              </section>
            </Col>
            <Col md={2}>
              <section className="Links">
                <header className="Links-title">
                  <h4>Links</h4>
                </header>

                <ul className="Links-box">
                  <li className="Links-box-item">
                    <Link to="/cleaning">Cleaning Facilities</Link>
                  </li>
                  <li className="Links-box-item">
                    <Link to="/about">About Us</Link>
                  </li>
                  <li className="Links-box-item">
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                  <li className="Links-box-item">
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li className="Links-box-item">
                    <Link to="/return">Return Policy</Link>
                  </li>
                  <li className="Links-box-item">
                    <Link to="/delivery-terms-conditions">
                      Delivery Terms and Condition
                    </Link>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className="contact">
                <header className="contact-title">
                  <h4>Customer Support</h4>
                </header>

                <ul className="contact-box">
                  <li className="contact-box-item">
                    <div className="iconHolder">
                      <i className="fa fa-map-marker"></i>
                    </div>
                    <div className="addHolder">
                      <div className="addHolder-key">
                        <strong>Location</strong>{" "}
                      </div>
                      <div className="addHolder-value ">
                        Beverveien 29 0596 OSLO, Norway
                      </div>
                    </div>
                  </li>
                  <li className="contact-box-item">
                    <div className="iconHolder">
                      <i className="fa fa-phone"></i>
                    </div>
                    <div className="addHolder">
                      <div className="addHolder-key">
                        <strong>Phone</strong>
                      </div>
                      <div className="addHolder-value ">+47 455 34 581</div>
                    </div>
                  </li>

                  <li className="contact-box-item">
                    <div className="iconHolder">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="addHolder">
                      <div className="addHolder-key">
                        <strong>Email</strong>{" "}
                      </div>
                      <div className="addHolder-value ">
                        <a href="mailto:info@sunrisemultitradelink.com">
                          <span style={{ color: "white" }}>
                            info@sunrisemultitradelink.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className="socialMedia">
                <header className="socialMedia-title">
                  <h4>Find Us On</h4>
                </header>

                <section className="socialWrapper">
                  <a
                    href={
                      "https://www.facebook.com/profile.php?id=100073829448967"
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="socialWrapper-icons">
                      <i className="fa fa-facebook facebook"></i>
                    </div>
                  </a>

                  <a
                    href={"https://www.instagram.com/ibahadurkc/"}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="socialWrapper-icons">
                      <i className="fa fa-instagram instagram"></i>
                    </div>
                  </a>
                  <a
                    href={"https://twitter.com/SUNRISE140479"}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="socialWrapper-icons">
                      <i className="fa fa-twitter twitter"></i>
                    </div>
                  </a>
                  <a
                    href={
                      "https://www.youtube.com/channel/UCLZnZQmXIkES444lGbKEPCw"
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="socialWrapper-icons">
                      <i className="fa fa-youtube youtube"></i>
                    </div>
                  </a>
                  <a
                    href={"https://www.tiktok.com/@sunrise.multitradelink"}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="socialWrapper-icons">
                      {/* <i className='fa fa-tik-tok'></i> */}
                      <img src={tiktokIcon} alt="" className="tiktok" />
                    </div>
                  </a>
                </section>

                <header className="socialMedia-title">
                  <h4>Organisation No.</h4>
                  <p> 826500212</p>
                </header>

                <header className="socialMedia-title mt-4">
                  <h4>We Accept</h4>
                </header>
                <img
                  src={VisaMaster}
                  alt=""
                  className="footerImage"
                  style={{ width: "10rem", marginTop: "1rem" }}
                />
                <img
                  src={paymentAcceptance}
                  alt=""
                  className="footerImage"
                  style={{ width: "10rem", marginTop: "1rem" }}
                />
                <img
                  src={Vipps}
                  alt=""
                  className="footerImage"
                  style={{ width: "10rem", marginTop: "1rem" }}
                />
                <img
                  src={American}
                  alt=""
                  className="footerImage"
                  style={{ width: "10rem", marginTop: "1rem" }}
                />
              </section>
            </Col>
          </Row>

          <hr />
          <section className="copyright">
            Sunrise multitradelink &copy; {new Date().getFullYear()} All Rights
            Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
